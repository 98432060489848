$font: "Lato";
$primary: #26296a;
$error: #ff0019;
$warning: #e85c24;
$info: #76c3ed;
$hover: #f2f2f2;
$tippy-color: $primary;
$line-image-height: 25px;
$grid-size: 6;
$grid-size-mobile: 5;
$grid-size-small-mobile: 4;

// Non default
$border-bus-decorator: "none";
$background-bus-decorator: "initial";
$bus-decorator-width: 24;
$bus-decorator-height: 20;
$border-other-decorator: "none";
$background-other-decorator: "initial";
$other-decorator-width: 24;
$other-decorator-height: 20;
$place-icon-default-width: 20;
$place-icon-default-height: 20;
$start-pin-width: 80;
$start-pin-height: 40;
$end-pin-width: 80;
$end-pin-height: 40;
