.lc-modal {
  position: fixed;
  top: 50px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 1);
  transition: all 250ms ease-in-out;
  opacity: 0;
  overflow: hidden;

  &.lc-active {
    opacity: 1;
    top: 0;
  }

  .lc-head {
    display: flex;
    align-items: center;
    padding: 0 15px;
    background: white;

    .lc-close {
      padding-right: 25px;
      margin-left: -10px;
      background: url(assetsPath("/assets/images/back.svg")) center center no-repeat;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &.legal-notice,
      &.add-entry,
      &.tutorial {
        padding-right: 0;
        position: absolute;
        right: 20px;
        background: url(assetsPath("/assets/images/clear.svg")) center center no-repeat;
      }
      &.route-calculation-print {
        padding-right: 0;
        position: absolute;
        background: url(assetsPath("/assets/images/clear.svg")) center center no-repeat;
        width: 18px;
        height: 18px;
        right: 18px;
      }
    }
  }

  &.legal-notice,
  &.add-entry,
  &.tutorial, 
  &.launch-modal, 
  &.route-calculation-print {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .lc-head {
      background: white;
      position: absolute;
      border-radius: 8px;
      top: 0;
      left: 40px;
      right: 40px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px;
      flex: 1;

      @include mobile {
        left: 0;
        right: 0;
        margin: 20px;
        max-height: 85vh;
      }
    }

    .lc-modal-content {
      width: 100%;
      height: 100%;
      word-wrap: anywhere;
      overflow-y: auto;
    }
  }

  &.route-calculation-print {
    .lc-head {
      top: unset;
    }
  }

  &.add-entry {
    .lc-modal-content {
      display: flex;
      flex-direction: column;

      .lc-frame-add-entry-title {
        margin: 0 2em;
        color: black;
      }
    }
  }

  .lc-legal-notice-page-name,
  .lc-add-entry-page-name {
    text-transform: uppercase;
    font-size: 0.925em;
    font-weight: 700;
    text-align: center;
  }

  .lc-legal-notice-title {
    font-weight: 700;
    margin: 15px 0px 5px 0px;
    font-size: 0.925em;
  }

  .lc-legal-notice-content {
    font-size: 0.875em;

    &.with-button {
      display: flex;
      flex-direction: column;
    }
  }

  .lc-legal-notice-button-change {
    background-color: #58585a;
    color: #ffffff;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px auto;

    &:hover {
      background-color: #808080;
    }
  }

  .lc-legal-notice-link {
    text-decoration: none;
    color: #999999;
  }

  .lc-input {
    border: none;
    border-radius: 5px;
    flex: 1;
    font-size: 16px !important;
    padding: 15px;
    font-family: $font, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif;
  }

  .lc-autocomplete {
    width: 100%;
    left: 0;
    right: 0;
    max-height: calc(100vh - 60px);
    overflow: auto;

    @supports (-webkit-touch-callout: none) {
      margin-top: 10px;
    }

    .lc-item {
      padding: 10px;
      font-size: 0.95em;
      font-weight: 600;

      img {
        padding: 5px 7px;
        height: 20px !important;
        min-width: 45px !important;
        margin-right: 10px;
      }
    }

    .lc-line {
      padding: 7px 10px !important;
    }
  }
}
