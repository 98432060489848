@mixin override {
  .lc-timetable {
    table {
      tr {
        td,
        th {
          border: none !important;
        }
        th {
          padding: 1px !important;
        }
      }
    }
  }

  .lc-radio {
    input:after {
      box-sizing: unset !important ;
    }
  }

  // Bonne solution ?
  .lc-calendar {
    button {
      font-size: 0.8em !important;
    }
    .lc-calendar-today-button {
      font-size: 0.8em !important;
    }
  }

  .lc-informations {
    &.lc-odt {
      border-color: #c90774;
      
      .lc-disruption-severity {
        color: #c90774 !important;

        .lc-icon {
          background: url(assetsPath("/assets/images/disruptions/informations-odt.svg")) center center no-repeat;
        }
      } 
    }
  }

  .lc-stop-name {
    &.selected {
      margin-top: -3px;
    }
  }

  .lc-pulsate {
    border: 3px solid #999;
    border-radius: 50%;
    height: 12px;
    width: 12px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: -1;
  }

  .lc-crossed:before,
  .lc-crossed:after {
    position: absolute;
    left: 3.2px;
    content: " ";
    height: 15px;
    top: -2.85px;
    width: 2px;
    background-color: #333;
  }

  .lc-crossed:before {
    transform: rotate(45deg);
  }

  .lc-crossed:after {
    transform: rotate(-45deg);
  }

  .lc-stop-terminus {
    .lc-crossed:before,
    .lc-crossed:after {
      left: 4px;
      top: -3px;
    }
  }

  .lc-infobox {
    .lc-infobox-title {
      .lc-infobox-title-tools {
        .lc-tool-route-calculation {
          background-size: 60%;
        }
      }
    }
  }
  .lc-stop-marker {
    margin-top: -26px;
  }
  .lc-stop-marker-terminus {
    z-index: 9999 !important;
  }
  .lc-circle-icon-marker-terminus {
    display: none;
  }

  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.3, 1.3);
      opacity: 0;
    }
  }

  .lc-inputCalculation {
    img {
      width: 40px !important;
    }
  }

  .lc-terminus-commune {
    font-style: normal !important;
    text-transform: uppercase;
  }

  .lc-line {
    .lc-blockIcon {
      margin-left: -5px;
      margin-top: -25px;
    }
  }

  @include mobile {
    .lc-journeys-search-summary.lc-elevation {
      margin: 0 0 15px;
    }
  }

  .lc-transport-title {
    color: $primary !important;
  }

  .lc-line-tad-header {
    text-transform: none !important;
  }

  .lc-route-calculation-markers {
    &.start-pin {
      margin-top: -#{$start-pin-height}px;
    }
    &.end-pin {
      margin-top: -#{$end-pin-height}px;
    }
  }
}
