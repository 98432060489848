.lc-module {
  &.lc-bikes {
    display: inherit;

    .lc-tab-panel {
      padding: 15px 20px 5px;
    }

    .lc-ul-bikes {
      margin: 0px;
      list-style-type: none;
      padding: 0 0 0 20px;
    }

    .lc-category {
      padding: 10px 0;
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 0;
  
      @include font-medium;
  
      img {
        padding-right: 10px;
      }
    }

    .lc-bike {
      height: inherit;
      cursor: pointer;
      padding: 5px;

      @include font-medium;

      &.lc-bike-selected {
        border-top: 1px solid $primary;
      }

      .lc-bike-active {
        font-weight: bold;
      }

      .lc-bike-content {
        .lc-bike-available-title,
        .lc-bike-address-title {
          margin-top: 7px;
          font-weight: bold;
        }

        &:hover {
          font-weight: bold;
        }

        .lc-bike-available {
          display: flex;
          align-items: center;
          padding: 10px 0 5px;

          .lc-loading {
            padding: 0;
            align-items: initial;

            img {
              width: 20px;
              padding: 0;
            }
          }

          .lc-available,
          .lc-capacity {
            display: flex;
            align-items: center;

            img {
              width: 25px;
            }
          }

          .lc-capacity {
            margin-left: 10px;

            img {
              width: 12px;
            }
          }
        }

        .lc-bike-prices {
          margin-top: 5px;
          .lc-bike-prices-title {
            margin-bottom: 3px;
            font-weight: bold;
          }
          ul {
            padding-inline-start: 0px;
            list-style-type: none;
          }
        }

        .lc-bike-price-url {
          margin-top: 5px;
          font-weight: bold;
          a {
            font-weight: normal;
          }
        }
      }
    }

    .lc-legend {
      display: flex;
      align-items: center;
      padding: 5px 0;

      .lc-image {
        display: flex;
      }

      .lc-name {
        margin-left: 15px;
      }

      .lc-toggle{
        margin-left: 10px;
        border: 1px solid $border-xs;
        border-radius: 3px;
        padding: 3px 7px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover{
          // background-color: #eee;
          border: 1px solid $border-m;
        }
        .lc-toggle-image{
          width: 15px;
          height: 13px;
        }
      }
    }
  }
}
