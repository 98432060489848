.lc-disruptions {
  z-index: 999;
  padding-left: 18px;
  margin-left: -18px;
  position: relative;

  .lc-disruptions-head {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;

    @include font-small;

    .lc-icon {
      background: url(assetsPath("/assets/images/disruptions/blocking.svg")) center center no-repeat;
      background-size: contain !important;
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }

    &.with-only-delays {
      background: $warning;
      .lc-icon {
        background: url(assetsPath("/assets/images/disruptions/delays.svg")) center center no-repeat;
      }
    }

    &.with-only-infos {
      background: $info !important;
      .lc-icon {
        background: url(assetsPath("/assets/images/disruptions/informations.svg")) center center no-repeat;
      }
    }
  }

  &.with-disruptions-opened {
    margin-left: -22px;
    border-left: 4px solid $error;
  }

  &.with-only-delays {
    .lc-disruptions-head {
      color: $warning;
    }

    &.with-disruptions-opened {
      border-left-color: $warning;
    }
  }

  &.with-only-infos {
    .lc-disruptions-head {
      color: $info;
    }

    &.with-disruptions-opened {
      border-left-color: $info;
    }
  }
}

.lc-blocking,
.lc-delays,
.lc-informations {
  padding: 0 15px;
  border-left: 5px solid;
  border-left-color: $error;
  color: $error;
  margin-top: 5px;

  .lc-icon {
    background-size: contain !important;
    width: 15px;
    height: 15px;
    margin: 0 15px 0 5px;
  }

  .lc-disruption {
    flex: 1;
  }

  .lc-disruption-severity {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.1em;

    .lc-icon {
      width: 15px;
      padding: 0;
      margin: 0 10px 0 0;
      flex-shrink: 0;
    }
  }

  .lc-disruption-severity::first-letter {
    text-transform: capitalize;
  }

  .lc-disruption-title {
    font-weight: bold;
    margin-top: 5px;
  }

  .lc-disruption-content {
    color: $text;
    margin: 5px 0;
    font-size: 14px;
    cursor: pointer;

    img {
      max-width: 100%;
    }

    &.lc-empty {
      font-style: italic;
    }

    p {
      margin: 0 0 5px !important;

      a {
        text-decoration: underline;
      }
    }
  }

  .lc-disruption-dates {
    font-weight: bold;
    font-size: 0.9em;
    color: $text;

    .lc-disruption-dates-more {
      color: #7a7a7a;
      padding-top: 5px;
      font-weight: normal;
      cursor: pointer;
    }

    .lc-collapsed {
      padding-top: 5px;
    }
  }
}

.lc-blocking {
  .lc-disruption-severity {
    color: $error;

    .lc-icon {
      background: url(assetsPath("/assets/images/disruptions/blocking.svg")) center center no-repeat;
    }
  }
}

.lc-delays {
  border-left-color: $warning;
  color: $warning;

  .lc-disruption-severity {
    color: $warning;

    .lc-icon {
      background: url(assetsPath("/assets/images/disruptions/delays.svg")) center center no-repeat;
    }
  }
}

.lc-informations {
  border-left-color: $info;
  color: $info;

  .lc-disruption-severity {
    color: $info;

    .lc-icon {
      background: url(assetsPath("/assets/images/disruptions/informations.svg")) center center no-repeat;
    }
  }
}

.lc-stops {
  .lc-disruptions {
    margin: -10px -10px 10px;
    padding: initial;

    &:before {
      content: none;
    }
  }

  .lc-disruptions-head {
    font-size: unset;
    background: $error;
    color: white;
    padding: 5px 20px;
  }

  .lc-stop {
    .lc-blocking,
    .lc-delays,
    .lc-informations {
      border-left: none;
      padding: 0 10px;
    }
  }
}

.lc-roadmap {
  .lc-disruptions {
    display: flex;
    flex-direction: column;

    .lc-disruptions-head {
      background: $error;
      color: white;
      padding: 5px 15px;
      margin-left: -18px;
      padding-left: 18px;
      align-self: flex-start;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.with-only-delays {
      .lc-disruptions-head {
        background: $warning;
      }
    }

    .lc-blocking,
    .lc-delays,
    .lc-informations {
      border: none;
      padding: 0;
    }
  }
}

.lc-section-notes {
  .lc-informations {
    display: inline-block;
    background: white;
    border-radius: 4px;
    margin-bottom: 15px;

    .lc-disruption-severity {
      margin-top: 10px;
    }

    .lc-disruption {
      margin-bottom: 10px;
    }
  }
}

.lc-infobox {
  .lc-blocking,
  .lc-delays,
  .lc-informations {
    color: white;
    padding: 5px 10px;
    white-space: nowrap;
    margin: 0;
    border: none;

    .lc-disruption-severity {
      color: white;
    }
  }
  .lc-blocking {
    background: $error;
  }

  .lc-delays {
    background: $warning;
  }

  .lc-informations {
    background: $info;
  }
}
