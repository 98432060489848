.lc-admin-valkyrie iframe {
  display: block;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0 none;
  box-sizing: border-box;
}

.lc-admin-center {
  width: 100%;
}

.lc-admin-header {
  font-size: 1.5em;
  background-color: $primary;
  padding: 20px;
  position: sticky;
  top: 0;
  height: 35px;
  display: flex;

  span {
    margin-left: 25px;
    color: $secondary;
  }

  @include mobile {
    height: 50px;
    font-size: 1.25em;
  }

  .lc-admin-header-back {
    cursor: pointer;
  }
}

.lc-admin-datas {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);

  @include mobile {
    height: calc(100vh - 90px);
  }
}

.lc-admin-cat {
  border-radius: 5px;
  border: 3px solid $primary;
  padding: 20px;
  margin: 20px;
}

.lc-admin-title {
  font-size: 1.3em;
  font-style: bold;
}

.lc-admin-content {
  display: flex;
  flex-wrap: wrap;

  @include mobile {
    flex-direction: column;
  }

  &.force-map-update {
    justify-content: center;
  }
}

.lc-admin-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  &.lc-admin-modal-close {
    cursor: pointer;
  }
}

.lc-admin-message {
  text-align: center;
  width: max-content;
  position: relative;
  padding: 20px;
  margin: 20px auto;
  color: white;
  border-radius: 5px;

  &.message-success {
    background-color: green;
  }

  &.message-error {
    background-color: red;
  }

  &.message-success,
  &.message-error {
    img {
      position: absolute;
      top: -5px;
      right: -5px;
      background-color: white;
      border-radius: 50%;
    }
  }

  &.message-loading {
    background-color: orange;
  }
}
